<template>
    <div>
        <b-modal         
        id="audio"
        title="Запись звонка"
        size="md"
        no-enforce-focus
        centered
        ok-only
        :no-close-on-backdrop="true">
        <div style="display: flex; justify-content: center; align-items: center;">
            <audio :src="`https://api-2taxi.gram.tj${audioSrc}`" controls>
            </audio>
        </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: ['audioSrc'],
    data(){ 
        return {
        }
    },
    methods: {
    },
    mounted() {
        
    },
}
</script>